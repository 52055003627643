















import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    show: true as boolean,
  // blobContent: { image: null } as any,
  }),
  mounted () {
    setTimeout(() => {
      this.checkScroll()
    }, 500)
    this.scroll()
  },
  methods: {
    scroll () {
      window.onscroll = () => {
        this.checkScroll()
      }
    },
    checkScroll () {
      const bottomOfWindow =
        Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) +
        window.innerHeight >=
        document.documentElement.offsetHeight - 200

      if (bottomOfWindow) {
        this.show = false // replace it with your code
      } else {
        this.show = true
      }
    },
    scrollToBottom () {
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
    },
  },
})
